<script lang="ts" setup>
import { Splide, SplideSlide, SplideTrack, Options } from '@splidejs/vue-splide'
import { Teaser } from '../components/TeaserSlide.vue'
import { Link } from '~/types'

defineProps<{
  title?: string
  subTitle?: string
  teasers: Teaser[]
  link?: Link
}>()

const options = {
  autoWidth: true,
  gap: 36,
  trimSpace: false,
  pagination: true,
  arrows: false,
  mediaQuery: 'min',
  breakpoints: {
    640: {
      pagination: false,
      arrows: true,
    },
    768: {
      gap: 48,
    },
  },
} as Options

const parentName = getCurrentInstance()?.parent?.type.__name

const { isOnLandingPage } = useChecks()
</script>

<template>
  <Section v-if="isOnLandingPage" large wide :parent-name="parentName">
    <div class="container grid">
      <SectionTitle :title="title" :sub-title="subTitle" />
      <Splide :options="options" :has-track="false" aria-label="My Favorite Images">
        <SplideTrack class="splide-track">
          <SplideSlide v-for="(slide, index) in teasers" :key="index" class="splide-slide">
            <TeaserSlide v-animate="{ animation: 'vertical-reveal' }" :teaser="slide" />
          </SplideSlide>
        </SplideTrack>
        <div v-animate="{ animation: 'vertical-reveal' }" class="splide__arrows">
          <ButtonNavigation class="splide__arrow--prev" />
          <ButtonNavigation class="splide__arrow--next" direction="right" />
        </div>
      </Splide>
      <Button v-if="link?.url" secondary icon="offers" :to="link.url">{{ link.text }}</Button>
    </div>
  </Section>

  <SectionSpecial v-else :title="title || ''" :sub-title="subTitle" :parent-name="parentName">
    <Splide :options="options" :has-track="false" aria-label="My Favorite Images">
      <SplideTrack class="splide-track">
        <SplideSlide v-for="(slide, index) in teasers" :key="index" class="splide-slide">
          <TeaserSlide v-animate="{ animation: 'vertical-reveal' }" :teaser="slide" />
        </SplideSlide>
      </SplideTrack>
      <div v-animate="{ animation: 'vertical-reveal' }" class="splide__arrows">
        <ButtonNavigation class="splide__arrow--prev" />
        <ButtonNavigation class="splide__arrow--next" direction="right" />
      </div>
    </Splide>
    <Button v-if="link?.url" secondary icon="offers" :to="link.url">{{ link.text }}</Button>
  </SectionSpecial>
</template>

<style lang="scss" scoped>
.box {
  overflow: hidden;
}

.section-special {
  :deep(.content .tl-10) {
    --span: 11;
  }
}

.splide {
  margin-bottom: 80px;
  @include breakpoint(ts) {
    margin-bottom: 0;
  }
}
.splide-track {
  @include breakpoint(t) {
    overflow: visible;
  }
}
.splide-slide {
  width: 100%;

  @include breakpoint(ts) {
    width: 340px;
  }

  @include breakpoint(t) {
    width: 364px;
  }

  @include breakpoint(ds) {
    width: 468px;
  }
}

.splide__arrows {
  display: flex;
  justify-content: flex-end;
  gap: 8px;

  margin-top: 35px;

  @include breakpoint(ts) {
    margin-top: 50px;
  }
}

.splide,
.button {
  @include breakpoint(ts) {
    grid-column-start: 1;
    grid-column-end: -1;
    grid-row: 2;
  }
}
.button.secondary {
  @include breakpoint(ts) {
    align-self: end;
    justify-self: start;
  }
}
</style>
